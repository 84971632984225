
html {
  background: url(../img/background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #0a304f;
}

body{
    position: relative;
    background-color: transparent;
}
/* You could use :after - it doesn't really matter */
body:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1000;
    opacity: 0;
}

.modal-backdrop.in {
	filter: alpha(opacity=50);
	opacity: .8;
}

.glyphicon img {
  	height: 50px;
}

.list-group {
  font-size: 18px;
}

.list-group .badge {
  font-size: 16px;
}

.posicao{
	background-color: #e3672a;
	font-size: 16px;
}

.money{
	background-color: #5cb85c;
}

.point{
	background-color: black;
	font-size: 16px;
}

.up{
	background-color: green;
}

.down{
	background-color: red;
}

.qndt{
	font-size: 24px
}

.escudo {
	padding-right: 10px;
	padding-bottom: 10px;
	margin: 10px;
  height: 30px;
}

.cartoleiro {
    position: relative;
    float: none;
    left: 10%;
    top: -10px;
}

.timeco {
   	position: relative;
    float: none;
    left: 10%;
    top: -40px;
    padding: 0px;
    margin: 0px;
}

.backimage{
	/*background-image: url('/static/img/escudo/sprite_escudos.png');*/
	background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.partida-logo img{
	height: 35px;
}

.partida-logo b{
	display: inline-block;
    vertical-align: middle;
    float: none;
    top: 8px;
    position: relative;
}
.placar{
	font-size: 24px;
    position: relative;
    top: 10px;
    /* border-style: solid; */
    padding: 8px;
    background-color: #f5f5f5;
}

.small{
		font-size: 12px;
}

.position{
		font-size: 16px;
}

.adlayout{
	height: 30px;
	width: 80%;
	overflow: hidden;
	position: absolute;
	top: 50px;
}

.escudo-placar{
	height: 40px;
    position: absolute;
    float: left;
    top: 20px;
    left: -2px;
}

.title-head{
	font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
	font-size: 36px;
}


@media only screen and (max-width: 500px) {
	.adlayout{
		height: 30px;
		width: 98%;
		overflow: hidden;
		position: absolute;
		top: 50px;
	}
	.position{
		font-size: inherit;
	}
    .glyphicon img {
	  	height: 25px;
	}
	.slug {
	  	height: 35px;
	}

	.list-group {
	  font-size: 12px;
	}

	.list-group .badge {
	  font-size: 11px;
	}

	.posicao{
		background-color: #e3672a;
		font-size: 11px;
	}

	.label-info {
	  	font-size: 11px;
	}

	.panel-body {
    	padding: 1px;
    	margin: 1px;
	}

	.container {
	    padding-right: 3px;
	    padding-left: 3px;
	    margin-right: auto;
	    margin-left: auto;
	}

	.list-group-item {
	    position: relative;
	    display: block;
	    padding: 2px 5px;
	    overflow: auto;
	}

	.timeres{
		font-size: 8px;
	}

	.propaganda{
		height: 40px;
	}
	.small{
		font-size: 8px;
	}

	.cartoleiro {
    	position: relative;
	    float: none;
	    left: 20%;
	    top: -25px;
	}

	.timeco {
    	position: relative;
	    float: none;
	    left: 20%;
	    top: -25px;
	    padding: 0px;
	    margin: 0px;
	}
	.panel-heading {
	    padding: 5px 3px;
	    border-bottom: 1px solid transparent;
	    border-top-left-radius: 3px;
	    border-top-right-radius: 3px;
    	font-size: 11px;
	}
	h1{
		font-size: 18px
	}

	.title-head{
		font-size: 16px
	}

	.backimage{
		background-image: url('../img/escudo/sprite_escudos.png');
		background-size: 20px 20px;
	    background-repeat: no-repeat;
	    background-position: 50% 50%;
	}

	.partida-logo img{
		height: 15px;
	}
	.partida-logo b{
		display: inline-block;
	    vertical-align: middle;
	    float: none;
	    top: 3px;
	    font-size:5px;
	    position: relative;
	}

	.placar{
		font-size: 16px;
	    position: relative;
	    top: 5px;
	    /* border-style: solid; */
	    padding: 3px;
	    background-color: #f5f5f5;
	}

	html {
	  background: url(../img/background-mobile.jpg) no-repeat center center fixed;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	}

	.escudo-placar {
		height: 15px;
		position: absolute;
		float: left;
		top: 10px;
		left: 0px;
		padding: 0px;
		margin: 0px;
	}

}

.navbar-text {
	font-weight: bolder;
}

.navbar-inverse .navbar-text {
  color: white;
}

.uppercase {
    text-transform: uppercase;
}

.ui-autocomplete-loading {
	background:url('../img/load.gif') no-repeat right center
}

.cartola{
    vertical-align: middle;
}

.info_scout{

}

.panel-body {
    padding-top: 3px;
}

.compartilhado {
	width: 100%;
	max-width: inherit;

}


.compartilhado img{
	width: inherit;
}

.addthis_toolbox a{
    width: auto;
    float: none !important;
}

.row {
    margin-right: -5px;
    margin-left: -5px;
}

/*.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {*/
/*    position: relative;*/
/*    min-height: 1px;*/
/*    padding-right: 5px;*/
/*    padding-left: 5px;*/
/*}*/



/*.holds-the-iframe {*/
/*  background:url('/static/img/load.gif') center center no-repeat;*/
/*	height: 98%;*/
/* }*/

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type="text"], input[type="number"], textarea{
    font-size: 16px;
  }
}

.text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 16px;     /* fallback */
   max-height: 16px;      /* fallback */
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.close-in{
	position: absolute;
    top: 0px;
    z-index: 100;
    float: right;
    right: 5px;
}

*.html2canvasreset{
    overflow: visible !important;
    width: auto !important;
    height: auto !important;
    max-height: auto !important;
}


.progress {
	margin-bottom: 5px;
}

.icon-logo {
	padding: 5px;
    margin-left: 10px;
}

.bootstrap-switch-modo-sw{
	float: right;
}

.classico{
  background-color: #FFF2F2;
}

.modal-backdrop.show {
    opacity: 0.8;
}

._loading_overlay_overlay{
    position: fixed !important;
}
